.ant-tabs-tab, .ant-tabs-tab:hover{
  background: #1BA0E2 !Important;
  color: #fff !Important;
}
/*.ant-tabs-tab:hover{
  background: #1BA0E2 !Important;
  color: #fff !important;
}*/
.ant-tabs-tab-active{
  background: #ccc !Important;
  color:  #1BA0E2 !Important;
}
.ant-tabs-tab-active:hover{
  background: #ccc !Important;
  color:  #1BA0E2 !Important;
}
.titik-dua::after{
  text-align: right;
  float: right;
  content: ": ";
}
.panel-tombol-aksi-pembayaran{
  position: fixed;
  bottom: 0;
  width: 100%;
  /*z-index: 10000;*/

}