/* @page {
    size: 20mm 30mm;
    margin: 0;
    size: portrait;
} */
.tiket-panel {
    width: 50mm;
    height: 20mm;
    page-break-after: always;
    text-align: center;
    /* border: 1px dotted #000; */
    padding-top: 3px;
    padding-bottom: 3px;
}
.urutan{
    padding: 10px;
    border-radius: 20%;
    border: 1px solid #000;
}
.qr-panel{
    height: 100%;
}
.tiket-title{
    font-size: 8px;
    font-weight: bold;
    margin-bottom: 1mm;
    text-align: left;
    width: 100%;

    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}
.tiket-keterangan{
    font-size: 7px;
    font-weight: bold;
    border: 1px solid #000;
    margin-right: 10px;
    text-align: left;
    padding: 5px;
}
.tiket-footer{
    width: 100%;
    text-align: center;
    font-size: 7px;
    font-style: italic;
    line-height: 1em;;
}
.bold{
    font-weight: bold;
}
@media print {

    .tiket-panel {
        width: 50mm;
        height: 20mm;
        page-break-after: always;
        text-align: center;
        /* border: 1px dotted #000; */
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .qr-panel{
        height: 100%;
    }
    .tiket-title{
        font-size: 8px;
        font-weight: bold;
        margin-bottom: 1mm;
        text-align: left;
        width: 100%;
    
        /* overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
    }
    .tiket-keterangan{
        font-size: 7px;
        font-weight: bold;
        border: 1px solid #000;
        margin-right: 10px;
        text-align: left;
        padding: 5px;
    }
    .tiket-footer{
        width: 100%;
        text-align: center;
        font-size: 7px;
        font-style: italic;
        line-height: 1em;;
    }
    .bold{
        font-weight: bold;
    }
}